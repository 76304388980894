import React from "react";
import QueueAnim from "rc-queue-anim";
import { Row } from "antd";
import { page3 } from "./data";

export default function Page3(props) {
  const renderDesc = (descriptions) => {
    const descHtml = descriptions.map((item, i) => (
      <div key={i.toString()} className="one-item">
        <span>{i + 1 + ")"}</span>
        <span className="value">{item}</span>
      </div>
    ));
    return descHtml;
  };
  const children = page3.map((card, i) => (
    <div className="one-card" key={i.toString()}>
      <div className="cardImg">
        {" "}
        <img src={card.img} alt={card.title} />
      </div>
      <div className="card-title">{card.title}</div>
      <div className="caed-detail">{renderDesc(card.descriptions)}</div>
    </div>
  ));

  if (props.isMobile) {
    return (
      <section id="FAQdesc" className="phone-faq-wrapper">
        <div className="phone-faq-title">{"FAQ"}</div>
        <div className="phone-faq-desc">
          {"El equipo de Hey Credito ha compilado una lista"}
          <br />
          {"de dudas comunes que esperan que le ayuden."}
        </div>
        <QueueAnim
          type="bottom"
          className="phone-faq-list"
          key="page3"
          component={Row}
        >
          {children}
        </QueueAnim>
      </section>
    );
  }

  return (
    <section id="FAQdesc" className="page3 text-center">
      <div>
        <p className="page3-title">{"FAQ"}</p>
        <p className="page3-subtitle">
          {"El equipo de Hey Credito ha compilado una lista"}
          <br />
          {"de dudas comunes que esperan que le ayuden."}
        </p>
        <QueueAnim type="bottom" className="allFaq" key="page3" component={Row}>
          {children}
        </QueueAnim>
      </div>
    </section>
  );
}
