import React from "react";
import DocumentTitle from "react-document-title";
import { enquireScreen } from "enquire-js";
import { Helmet } from "react-helmet";
import Faq from "./Faq";
import "./static/new.less";
import hLogo from "../assets/new/h_logo.webp";
import hLogoPng from "../assets/new/h_logo.png";
import bg1 from "../assets/new/bg1.webp";
import bg1Png from "../assets/new/bg1.png";
import bg2 from "../assets/new/bg2.webp";
import bg2Png from "../assets/new/bg2.png";
import bg3 from "../assets/new/bg3.webp";
import bg3Png from "../assets/new/bg3.png";
import bg4 from "../assets/new/bg4.webp";
import bg4Png from "../assets/new/bg4.png";
import gp from "../assets/new/gp.webp";
import gpPng from "../assets/new/gp.png";
import pc1 from "../assets/new/pc1.webp";
import pc2 from "../assets/new/pc2.webp";
import pc3 from "../assets/new/pc3.webp";
import pc1Png from "../assets/new/pc1.png";
import pc2Png from "../assets/new/pc2.png";
import pc3Png from "../assets/new/pc3.png";
import icon1 from "../assets/new/icon1.webp";
import icon2 from "../assets/new/icon2.webp";
import icon3 from "../assets/new/icon3.webp";
import icon1Png from "../assets/new/icon1.png";
import icon2Png from "../assets/new/icon2.png";
import icon3Png from "../assets/new/icon3.png";
import downImg from "../assets/new/downImg.webp";
import logo from "../assets/new/logo.png";
import { Link } from "react-router-dom";

let isMobile = false;
enquireScreen((b) => {
  isMobile = b;
});

class Home extends React.PureComponent {
  state = {
    isMobile,
    showShadow: false,
    isBgWhite: false,
    activeHome: false,
    activeProduct: false,
    activefaq: false,
    isScroll: false,
    appUrl: null,
  };

  componentDidMount() {
    enquireScreen((b) => {
      this.setState({
        isMobile: !!b,
      });
    });
    window.addEventListener("scroll", this.bindScroll);
    fetch(window.location.origin + "/api/download/page/config?hash=1", {
      method: "GET",
      headers: { "Content-Type": "application/json;charset=UTF-8" },
      mode: "no-cors",
      cache: "default",
    })
      .then((res) => res.json())
      .then((res) => {
        if (res.ret === 0) {
          const { data = {} } = res;
          this.setState({
            appUrl: data.url,
          });
        }
      });
  }

  bindScroll = (event) => {
    this.setState({
      isScroll: true,
    });
    const scrollTop =
      (event.srcElement ? event.srcElement.documentElement.scrollTop : false) ||
      window.pageYOffset ||
      (event.srcElement ? event.srcElement.body.scrollTop : 0);
    if (scrollTop > 0) {
      this.setState({
        isBgWhite: true,
      });
    } else {
      this.setState({
        isBgWhite: false,
      });
    }

    // const homeOT = document.getElementById('home').offsetTop - 64;
    // const producteOT =
    //   document.getElementById("product").offsetTop - window.innerHeight / 2;
    // const faqOT =
    //   document.getElementById("FAQ").offsetTop - window.innerHeight / 2;
    // if (scrollTop < producteOT) {
    //   this.setState({
    //     activeHome: false,
    //     activeProduct: false,
    //     activefaq: false,
    //   });
    // } else if (producteOT < scrollTop && scrollTop < faqOT) {
    //   this.setState({
    //     activeHome: false,
    //     activeProduct: true,
    //     activefaq: false,
    //   });
    // } else if (scrollTop > faqOT) {
    //   this.setState({
    //     activeHome: false,
    //     activeProduct: false,
    //     activefaq: true,
    //   });
    // }
  };
  navToShadow = (e) => {
    this.setState({ showShadow: e.mode === "leave" });
  };
  jumpToSection = (id) => {
    let element = document.getElementById(id);
    if (element) {
      // 64 header高度
      window.scrollTo(0, element.offsetTop - 30);
    }
  };

  handleDown = () => {
    window.location.href = 'https://www.heycredito.com/HeyCredito-2.0.0-GP-release.apk'
  }

  render() {
    const { isMobile, appUrl } = this.state;
    let isDownload = window.location.pathname.indexOf('/download') > -1
    const renderBlock1 = () => {
      const title1 = "Obtén un préstamo ahora";
      const desc1 = "Sin codeudor";
      const desc2 = "Sin institución crediticia";
      const desc3 = "Sin verificar ingresos";
      return (
        <section id="header" className={`header ${isMobile && "header-mob"}`}>
          <div className="logo">
            <picture>
              <source srcset={hLogo} type="image/webp" />
              <img
                src={hLogoPng}
                className="hImg"
                alt="Obtén un préstamo ahora"
              />
            </picture>
          </div>
          <picture>
            <source srcset={bg1} type="image/webp" />
            <img src={bg1Png} className="bg1" alt="Sin codeudor" />
          </picture>
          <div className="info">
            <div>
              <h1 className="title1">{title1}</h1>
              <ul>
                <li className="desc1">{desc1}</li>
                <li className="desc1">{desc2}</li>
                <li className="desc1">{desc3}</li>
              </ul>

              {isDownload ? 
              <div className='download' onClick={()=>this.handleDown()}>
                <img  src={downImg} className={'downImg'} alt='Descargar HeyCredito'/>
                Descargar HeyCredito
              </div>
              :
              <a target="_blank" href={appUrl} rel="noopener noreferrer">
                <span>Obtén un préstamo ahora</span>
                <picture>
                  <source srcset={gp} type="image/webp" />
                  <img
                    src={gpPng}
                    className="gp"
                    alt="Sin institución crediticia"
                  />
                </picture>
              </a>}
            </div>
            <picture>
              <source srcset={pc1} type="image/webp" />
              <img src={pc1Png} className="pc1" alt="Sin verificar ingresos" />
            </picture>
          </div>
        </section>
      );
    };
    const renderBlock2 = () => {
      const title1 = "100% solicitud online";
      const title2 = "* Aplicación móvil en línea las 24 horas";
      const desc1 = "Revisión Inteligente";
      const tip1 =
        "Aprobación inteligente en línea para una revisión más rápida.";
      const desc2 = "Préstamos Rápidos";
      const tip2 =
        "Una vez aprobada la solicitud, el dinero llegará a su cuenta en 3 minutos.";
      const desc3 = "Más Seguro";
      const tip3 =
        "Protección de datos cifrados para una mayor seguridad y confiabilidad.";
      const title3 = "El proceso de solicitud es eficiente";
      const desc21 = "Envío de la información";
      const desc22 = "Revisión de la solicitud";
      const desc23 = "Recibirás el dinero";
      return (
        <section id="online" className={`block2 ${isMobile && "block2-mob"}`}>
          <picture>
            <source srcset={bg2} type="image/webp" />
            <img src={bg2Png} className="bg2" alt="online" />
          </picture>
          <h2 className="title21">{title1}</h2>
          <h2 className="title21">{title2}</h2>
          <div className="info1">
            <div className="card1">
              <picture>
                <source srcset={icon1} type="image/webp" />
                <img
                  src={icon1Png}
                  className="icon1"
                  alt="Revisión Inteligente"
                />
              </picture>
              <div className="desc21">{desc1}</div>
              <div className="tip1">{tip1}</div>
            </div>
            <div className="card1">
              <picture>
                <source srcset={icon2} type="image/webp" />
                <img src={icon2Png} className="icon1" alt="Más Seguro" />
              </picture>
              <div className="desc21">{desc2}</div>
              <div className="tip1">{tip2}</div>
            </div>
            <div className="card1">
              <picture>
                <source srcset={icon3} type="image/webp" />
                <img src={icon3Png} className="icon1" alt="Más Seguro" />
              </picture>
              <div className="desc21">{desc3}</div>
              <div className="tip1">{tip3}</div>
            </div>
          </div>
          <div className="info2">
            <picture>
              <source srcset={pc2} type="image/webp" />
              <img
                src={pc2Png}
                className="pc2"
                alt="El proceso de solicitud es eficiente"
              />
            </picture>
            <div className="i2Right">
              <div className="i2title">{title3}</div>
              <div className="i2Row">
                <span>1</span>
                <span>{desc21}</span>
              </div>
              <div className="i2RowLine" />
              <div className="i2Row">
                <span>2</span>
                <span>{desc22}</span>
              </div>
              <div className="i2RowLine" />
              <div className="i2Row">
                <span>3</span>
                <span>{desc23}</span>
              </div>
            </div>
          </div>
        </section>
      );
    };
    const renderBlock3 = () => {
      const desc1 = "Descargue la aplicación Hey Credito de";
      const desc2 = "inmediato y disfrute de la plataforma de";
      const desc3 = "préstamos más confiable y el servicio";
      const desc4 = "excepcional en México, ¡inicie su viaje de";
      const desc5 = "préstamos conveniente!";

      return (
        <section id="faq" className={`block3 ${isMobile && "block3-mob"}`}>
          {/* <img className="bg3" src={bg3} alt="bg3" /> */}
          <picture>
            <source srcset={bg3} type="image/webp" />
            <img src={bg3Png} className="bg3" alt="Hey Credito" />
          </picture>
          <div className="info31">
            <div className="desc31">{desc1}</div>
            <div className="desc31">{desc2}</div>
            <div className="desc31">{desc3}</div>
            <div className="desc31">{desc4}</div>
            <div className="desc31">{desc5}</div>
            <a
              className="action"
              target="_blank"
              rel="noopener noreferrer"
              alt="El proceso de solicitud es eficiente"
              href={appUrl}
            >
              <span>El proceso de solicitud es eficiente</span>
              <img className="gp3" src={gp} alt="gp3" />
              <picture>
                <source srcset={pc3} type="image/webp" />
                <img src={pc3Png} className="pc3" alt="préstamos conveniente" />
              </picture>
            </a>
          </div>
          <Faq />
        </section>
      );
    };
    const renderBlock4 = () => {
      const title1 = "Contactar";
      const desc1 = "serviceheycredito@gmail.com";
      const title2 = "Regulaciones";
      const desc21 = "《Acuerdo de Privacidad》";
      const desc22 = "《Acuerdo de Servicio》";
      return (
        <section className={`block4 ${isMobile && "block4-mob"}`}>
          <picture>
            <source srcset={bg4} type="image/webp" />
            <img src={bg4Png} className="bg4" alt="Regulaciones" />
          </picture>
          <div className="footer">
            Copyright © 2019-2021 | Hey Credito | All Rights Reserved
          </div>
          <img
            alt="serviceheycredito@gmail.com"
            className="b4Logo"
            src={logo}
          />
          <div className="b4Row">
            <div className="b4title">{title1}</div>
            <div className="b4desc">{desc1}</div>
          </div>
          <div className="b4Row">
            <div className="b4title">{title2}</div>
            <div className="b4desc">
              <Link
                to="/privacyAgreement"
                target="_blank"
                className="b4link"
                rel="noopener noreferrer"
              >
                {desc21}
              </Link>
            </div>
            <div className="b4desc">
              <Link
                to="/creditAgreement"
                className="b4link"
                target="_blank"
                rel="noopener noreferrer"
              >
                {desc22}
              </Link>
            </div>
          </div>
        </section>
      );
    };
    return (
      <>
        <Helmet>
          <title>Hey Credito</title>
          <meta
            name="description"
            content="Hey Credito es una popular aplicación de préstamos en línea que ofrece préstamos rápidos y convenientes en México. Con esta aplicación de préstamos personales, puede pedir dinero prestado y solicitar préstamos rápidos en efectivo para satisfacer sus necesidades de préstamos financieros. Si tiene poco dinero en efectivo y está tratando de encontrar una aplicación de préstamos confiable para pedir dinero prestado, contáctenos para obtener préstamos en efectivo y servicios de préstamos en línea."
          />
        </Helmet>
        <div className="narmal">
          {renderBlock1()}
          {renderBlock2()}
          {renderBlock3()}
          {renderBlock4()}
          <DocumentTitle title="Hey Credito" />
        </div>
      </>
    );
  }
}
export default Home;
