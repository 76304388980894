import faq1Img from "../assets/new/icon21.png";
import faq2Img from "../assets/new/icon22.png";
import faq3Img from "../assets/new/icon23.png";

export const page3 = [
  {
    img: faq1Img,
    title: "¿Qué cualificaciones se requieren？",
    descriptions: [
      "Un número de teléfono en uso",
      "Identificación gubernamental válida",
      "Ingresos estables",
      "Edad entre 18 y 49 años",
    ],
  },
  {
    img: faq2Img,
    title: "¿Cómo solicitar un préstamo?",
    descriptions: [
      "Instale Hey Credito y regístrese con su propio número de teléfono.",
      "Rellene el formulario de solicitud de préstamo en pesos.",
      "Espere la aprobación del préstamo en efectivo.",
      "Reclame su préstamo personal.",
    ],
  },
  {
    img: faq3Img,
    title: "¿Cómo pagar el préstamo en efectivo?",
    descriptions: [
      "Banco - CLABE: Deposita el dinero en la cuenta corporativa CLABE de Hey Credito indicada en la aplicación.",
      "OXXO: Vaya al OXXO más cercano, rellene la información requerida en el formulario de pago de facturas o muestre el código de barras al cajero, reembolse sus préstamos personales y reciba un recibo de confirmación de pago.",
    ],
  },
];
