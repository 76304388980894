import React, { Component } from "react";
import Home from "./Home";
import CreditAgreement from "./Page/CreditAgreement";
import PrivacyAgreement from "./Page/PrivacyAgreement";
import ServiceAgreement from "./Page/ServiceAgreement";
import { Router, Route, Redirect, Switch } from "react-router-dom";
import { createBrowserHistory } from "history";
const browserHistory = createBrowserHistory();
class App extends Component {
  componentDidMount() {
    if (window.location.href.indexOf("/#/creditAgreement") > -1) {
      window.location.href = "/creditAgreement";
    }
    if (window.location.href.indexOf("/#/privacyAgreement") > -1) {
      window.location.href = "/privacyAgreement";
    }
    if (window.location.href.indexOf("/#/serviceAgreement") > -1) {
      window.location.href = "/serviceAgreement";
    }
  }
  render() {
    return (
      <Router history={browserHistory}>
        <Switch>
          <Route exact path="/" component={Home} />
          <Route exact path="/download" component={Home} />
          <Route exact path="/creditAgreement" component={CreditAgreement} />
          <Route exact path="/privacyAgreement" component={PrivacyAgreement} />
          <Route exact path="/serviceAgreement" component={ServiceAgreement} />
          <Redirect to="/" />
        </Switch>
      </Router>
    );
  }
}

export default App;
